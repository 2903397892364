<template>
  <b-card class="p-0 report-table">
    <b-card-header class="d-flex align-items-center py-3">
      <div class="d-inline-flex align-items-center">
        <h4 v-shtml="(tableItems && tableItems.length) || 0" class="text-gray-900 my-0 mr-2" />
        <span>{{ $t('rCity_tbl_found') }}</span>
      </div>
      <div class="ml-auto">
        <h3 v-shtml="(reportTotals && reportTotals.amount) || 0" class="text-gray-900 my-0" />
      </div>
      <b-dropdown :text="$t('eLt_export_title')" class="m-md-2" size="sm">
        <b-dropdown-item @click="onSaveCsv">
          {{ $t('eLt_export_csv') }}
        </b-dropdown-item>
        <b-dropdown-item @click="onSaveXls">
          {{ $t('eLt_export_xls') }}
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>
    <b-table
      :fields="fields"
      :items="tableItems"
      :sticky-header="`${height}px`"
      class="text-nowrap"
      striped
      hover
      small
      responsive
      foot-clone
      thead-class="bg-primary text-white text-center small"
    >
      <template
        v-for="(date) in dates"
        #[`head(${date}_amount)`]="data"
      >
        <span
          :key="date"
          v-shtml="data.label"
          class="d-block text-center"
        />
      </template>
      <template
        v-for="(date, i) in dates"
        #[`foot(${date}_amount)`]
      >
        <span
          :key="date"
          v-shtml="(reportTotals && reportTotals.partial && reportTotals.partial[`${i}_amount`]) || '-'"
          class="d-block text-right"
        />
      </template>
      <template
        #[`foot(total_amount)`]
      >
        <span
          v-shtml="(reportTotals && reportTotals.amount) || '-'"
          class="d-block text-right"
        />
      </template>
      <template
        #[`foot(total_users)`]
      >
        <span
          v-shtml="(reportTotals && reportTotals.users) || '-'"
          class="d-block text-right"
        />
      </template>
      <template
        #[`foot(name)`]
      >
        <span class="d-block text-left">
          Total:
        </span>
      </template>

      <template
        v-for="(date) in dates"
        #[`cell(${date}_amount)`]="data"
      >
        <span
          :key="date"
          v-shtml="data.value"
          class="d-block text-right"
        />
      </template>

      <template
        #[`cell(total_users)`]="data"
      >
        <span
          v-shtml="data.value"
          class="d-block text-right font-weight-bold"
        />
      </template>
      <template
        #[`cell(total_amount)`]="data"
      >
        <span
          v-shtml="data.value"
          class="d-block text-right font-weight-bold"
        />
      </template>
      <template #cell(market)="data">
        <market-flag :market="getMarketNameById(data.item.market_id)" />
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import moment from 'moment'

export default {
  name: 'CitiesReportTable',
  components: {},
  props: {
    height: {
      type: Number,
      default: 500
    }
  },
  data () {
    return {
      thBgColor: '#007bff !important',
      thColor: '#fff !important'
    }
  },
  computed: {
    ...mapFields({
      filterData: 'reports.citiesReport.filterData',
      reportData: 'reports.citiesReport.reportData',
      reportTotals: 'reports.citiesReport.reportTotals',
      reportDates: 'reports.citiesReport.reportDates'
    }),
    items () {
      return this.reportData || []
    },
    totals () {
      return this.reportTotals || {}
    },
    dates () {
      return this.reportDates
    },
    fields () {
      const fields = [
        {
          key: 'city_name',
          label: this.$t('rCity_tbl_f_name'),
          sortable: false,
          stickyColumn: true,
          class: 'text-left',
          thClass: 'text-left'
        },
        {
          key: 'market',
          label: this.$t('rCity_tbl_f_market'),
          class: 'col-1 text-center',
          thClass: 'text-center'
        },
        // {
        //   key: 'total_users',
        //   label: this.$t('rCity_tbl_f_count_users'),
        //   sortable: false,
        //   thClass: 'text-center',
        //   class: 'text-right'
        // },
        {
          key: 'total_amount',
          label: this.$t('rCity_tbl_f_amount'),
          sortable: false,
          thClass: 'col-md-1 text-center',
          class: 'text-right'
        }
      ]
      if (this.dates?.length) {
        const countDates = this.dates?.length
        // generate columns
        this.dates.forEach((v, i) => {
          if (countDates - 1 !== i) {
            const currDate = this.$dateFormat(this.dates[i], 'DD/MM/YYYY')
            const nextDate = this.$dateFormat(moment(this.dates[i + 1]).subtract('days', 1).format('YYYY-MM-DD'), 'DD/MM/YYYY')
            fields.push({
              key: `${v}_amount`,
              label: `<span class="badge badge-pill badge-light" style="font-size:.8rem">${i + 1}</span>
                <small class="text-white d-block mt-1" style="line-height:1.2em">${currDate}<br/>${nextDate}</small>`,
              sortable: false,
              class: 'align-middle text-center',
              formatter: (value, key, item) => {
                let result = value
                if (!result && item) {
                  key = key.replace(/_amount/, '')
                  result = ''
                  if (item[`${key}_amount`]) {
                    result += `<b>${item[`${key}_amount`]}</b>`
                  }
                }
                if (!result) {
                  result = '-'
                }
                return result
              }
            })
          }
        })
      }
      return fields
    },
    tableItems () {
      const items = []
      if (this.items && this.items.length) {
        for (const i of this.items) {
          items.push(i)
        }
      }
      return items
    }
  },
  methods: {
    onSaveCsv () {
      this.$emit('save-csv')
    },
    onSaveXls () {
      this.$emit('save-xls')
    },
    getMarketNameById (id) {
      if (this.filterData?.market?.length > 0) {
        const market = this.filterData.market.find(i => i.value === id)
        if (market) {
          return market.text
        }
      }
      return null
    }
  }
}
</script>

<style>
.report-table .table.b-table > thead > tr > .table-b-table-default {
  background:#007bff !important;
  color:#fff !important;
}
.report-table .table.b-table > tfoot > tr > th {
  background: #737373 !important;
  color:#fff !important;
  text-align: right !important;
}
</style>
