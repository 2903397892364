<template>
  <b-card class="report-filter">
    <b-row>
      <b-col cols="12" md="6" lg="2">
        <b-form-group
          :label="$t('rCity_filter_f_from')"
          label-size="sm"
        >
          <month-picker-input
            v-model="from"
            date-format="YYYY-MM-01"
            display-format="MMMM, YYYY"
            :max-date="to"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="2">
        <b-form-group
          :label="$t('rCity_filter_f_to')"
          label-size="sm"
        >
          <month-picker-input
            v-model="to"
            date-format="YYYY-MM-01"
            display-format="MMMM, YYYY"
            :min-date="from"
            :max-date="maxDate"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="1">
        <b-form-group
          :label="$t('rCity_filter_f_interval')"
          label-size="sm"
        >
          <b-form-select
            v-model="interval"
            :options="filterData.interval"
            size="sm"
            label-field="text"
            value-field="value"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="1">
        <b-form-group
          :label="$t('rCity_filter_f_market')"
          label-size="sm"
        >
          <b-form-select
            v-model="market"
            :options="filterData.market"
            size="sm"
            label-field="text"
            value-field="value"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="1">
        <b-form-group
          :label="$t('rCity_filter_f_affiliate')"
          label-size="sm"
        >
          <b-form-select
            v-model="affiliate"
            :options="filterData.affiliate"
            size="sm"
            label-field="text"
            value-field="value"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="1">
        <b-form-group
          :label="$t('rCity_filter_f_source')"
          label-size="sm"
        >
          <b-form-select
            v-model="source"
            :options="filterData.source"
            size="sm"
            label-field="text"
            value-field="value"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="1">
        <b-form-group
          :label="$t('rCity_filter_f_campaign')"
          label-size="sm"
        >
          <b-form-select
            v-model="campaign"
            :options="filterData.campaign"
            size="sm"
            label-field="text"
            value-field="value"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="1">
        <b-form-group
          :label="$t('rCity_filter_f_medium')"
          label-size="sm"
        >
          <b-form-select
            v-model="medium"
            :options="filterData.medium"
            size="sm"
            label-field="text"
            value-field="value"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="1">
        <b-form-group
          :label="$t('rCity_filter_f_referer')"
          label-size="sm"
        >
          <b-form-input
            v-model="referer"
            size="sm"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="d-flex align-items-center justify-content-end">
        <b-btn variant="primary" size="sm" class="mr-2" @click="onSubmit">
          Submit
        </b-btn>
        <b-btn variant="secondary" size="sm" @click="resetFilter">
          Reset
        </b-btn>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import * as moment from 'moment'
export default {
  name: 'CitiesReportFilter',
  data () {
    return {
      maxDate: moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD')
    }
  },
  computed: {
    ...mapFields({
      filterData: 'reports.citiesReport.filterData',
      from: 'reports.citiesReport.filter.from',
      to: 'reports.citiesReport.filter.to',
      interval: 'reports.citiesReport.filter.interval',
      user_type: 'reports.citiesReport.filter.user_type',
      affiliate: 'reports.citiesReport.filter.affiliate',
      source: 'reports.citiesReport.filter.source',
      campaign: 'reports.citiesReport.filter.campaign',
      medium: 'reports.citiesReport.filter.medium',
      market: 'reports.citiesReport.filter.market',
      referer: 'reports.citiesReport.filter.referer',
      page: 'reports.citiesReport.page'
    })
  },
  mounted () {
    this.fetchFilterData()
  },
  methods: {
    ...mapActions({
      fetchFilterData: 'reports/citiesReport/fetchFilterData',
      resetFilter: 'reports/citiesReport/resetFilter'
    }),
    onSubmit () {
      this.page = 1
      this.$nextTick(() => {
        this.$emit('submit')
      })
    }
  }
}
</script>

<style>
  .month-picker-input-container {
    position: initial;
  }
  .month-picker-input {
    margin: 0 !important;
  }
  .month-picker__container {
    z-index: 10;
  }
</style>
