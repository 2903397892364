<template>
  <b-card class="report-filter">
    <b-row>
      <b-col cols="12" md="6" lg="2">
        <b-form-group
          :label="$t('rHostessChat_filter_f_from')"
          label-size="sm"
        >
          <month-picker-input
            v-model="startDate"
            date-format="YYYY-MM-01"
            display-format="MMMM, YYYY"
            :max-date="stopDate"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="2">
        <b-form-group
          :label="$t('rHostessChat_filter_f_to')"
          label-size="sm"
        >
          <month-picker-input
            v-model="stopDate"
            date-format="YYYY-MM-01"
            display-format="MMMM, YYYY"
            :min-date="startDate"
            :max-date="maxDate"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="2">
        <b-form-group
          :label="$t('rHostessChat_filter_f_interval')"
          label-size="sm"
        >
          <b-form-select
            v-model="interval"
            :options="filterData.interval"
            size="sm"
            label-field="text"
            value-field="value"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="2">
        <b-form-group
          :label="$t('rHostessChat_filter_f_platform')"
          label-size="sm"
        >
          <b-form-select
            v-model="platform"
            :options="filterData.platform"
            size="sm"
            label-field="text"
            value-field="value"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          :label="$t('rHostessChat_filter_f_market')"
          label-size="sm"
        >
          <b-form-select
            v-model="market"
            :options="filterData.market"
            size="sm"
            label-field="text"
            value-field="value"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="d-flex align-items-center justify-content-end">
        <b-btn variant="primary" size="sm" class="mr-2" @click="fetchReportData">
          Submit
        </b-btn>
        <b-btn variant="secondary" size="sm" @click="resetFilter">
          Reset
        </b-btn>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import * as moment from 'moment'
export default {
  name: 'HostesChatReportFilter',
  data () {
    return {
      maxDate: moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD')
    }
  },
  computed: {
    ...mapFields({
      filterData: 'reports.hostesChatReport.filterData',
      market: 'reports.hostesChatReport.filter.market',
      platform: 'reports.hostesChatReport.filter.platform',
      interval: 'reports.hostesChatReport.filter.interval',
      startDate: 'reports.hostesChatReport.filter.startDate',
      stopDate: 'reports.hostesChatReport.filter.stopDate'
    })
  },
  mounted () {
    this.fetchFilterData()
  },
  methods: {
    ...mapActions({
      fetchReportData: 'reports/hostesChatReport/fetchReportData',
      fetchFilterData: 'reports/hostesChatReport/fetchFilterData',
      resetFilter: 'reports/hostesChatReport/resetFilter'
    })
  },
  async onSubmit () {
    await this.fetchReportData()
    this.$emit('submit')
  }
}
</script>

<style scoped>

</style>
