<template>
  <div class="report" :style="reportStyle">
    <hostes-chat-report-filter @submit="onFilterSubmit" />
    <hostes-chat-report-table
      :height="height"
      @page-changed="onPageCanged"
      @page-size-changed="onPageCanged"
      @save-csv="onSaveCsv"
      @save-xls="onSaveXls"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import HostesChatReportFilter from './HostesChatReport/HostesChatReportFilter'
import HostesChatReportTable from './HostesChatReport/HostesChatReportTable'
import { isServer } from '@/mixins/helpers'

export default {
  name: 'HostesChatReport',
  components: { HostesChatReportTable, HostesChatReportFilter },
  data () {
    return {
      height: 500
    }
  },
  computed: {
    ...mapGetters({
      deviceInfo: 'device'
    }),
    ...mapFields({
      reportCsv: 'reports.hostesChatReport.reportCsv',
      reportXls: 'reports.hostesChatReport.reportXls'
    }),
    reportStyle () {
      let style = ''
      if (this.deviceInfo?.type === 'mobile' || this.deviceInfo?.type === 'tablet') {
        style += 'overflow: hidden; overflow-y: auto; max-height: calc(100svh - 80px);'
      }
      return style
    }
  },
  async mounted () {
    this.calcHeight()
    await this.fetchReportData()
    this.$nextTick(() => {
      this.calcHeight()
    })
    if (window) {
      window.addEventListener('resize', this.calcHeight)
    }
  },
  beforeDestroy () {
    if (window) {
      window.removeEventListener('resize', this.calcHeight)
    }
  },
  methods: {
    ...mapActions({
      fetchReportData: 'reports/hostesChatReport/fetchReportData',
      fetchReportCsv: 'reports/hostesChatReport/fetchReportCsv',
      fetchReportXls: 'reports/hostesChatReport/fetchReportXls'
    }),
    async onFilterSubmit () {
      await this.fetchReportData()
      this.$nextTick(() => {
        this.calcHeight()
      })
    },
    async onPageCanged (page) {
      await this.fetchReportData()
      this.$nextTick(() => {
        this.calcHeight()
      })
    },
    calcHeight () {
      if (!isServer && window && this.deviceInfo?.type !== 'mobile') {
        const $navbar = document.querySelector('.navbar')
        const $tFilter = document.querySelector('.report-filter')
        const $tHead = document.querySelector('.report-table .table thead')
        const $tHeader = document.querySelector('.report-table .card-header')
        const $tPager = document.querySelector('.report-table .pager')
        const navbarHeight = $navbar?.clientHeight || 0
        const tFilterHeight = $tFilter?.clientHeight || 0
        const tHeadHeight = $tHead?.clientHeight || 0
        const tHeaderHeight = $tHeader?.clientHeight || 0
        const tPagerHeight = $tPager?.clientHeight || 0
        const windowHeight = window.innerHeight
        this.height = windowHeight - navbarHeight - tFilterHeight - tHeadHeight - tHeaderHeight - tPagerHeight
      }
    },
    async onSaveCsv () {
      await this.fetchReportCsv()
      const k = moment().format('DDMMYYHHmmss')
      const filename = `hostes-chat-report-${k}.csv`
      const file = new Blob([this.reportCsv], { type: 'text/plain;charset=utf-8' })
      this.$nextTick(() => {
        this.reportCsv = null
      })
      if (window.navigator.msSaveOrOpenBlob) {
        // IE10+
        window.navigator.msSaveOrOpenBlob(file, filename)
      } else {
        // Others.
        const a = document.createElement('a')
        const url = URL.createObjectURL(file)
        a.href = url
        a.download = filename
        document.body.appendChild(a)
        a.click()
        this.$nextTick(() => {
          document.body.removeChild(a)
          window.URL.revokeObjectURL(url)
        })
      }
    },
    async onSaveXls () {
      await this.fetchReportXls()
      const k = moment().format('DDMMYYHHmmss')
      const filename = `hostes-chat-report-${k}.xlsx`
      const file = new Blob([this.reportXls], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      this.$nextTick(() => {
        this.reportXls = null
      })
      if (window.navigator.msSaveOrOpenBlob) {
        // IE10+
        window.navigator.msSaveOrOpenBlob(file, filename)
      } else {
        // Others
        const a = document.createElement('a')
        const url = URL.createObjectURL(file)
        a.href = url
        a.download = filename
        document.body.appendChild(a)
        a.click()
        this.$nextTick(() => {
          document.body.removeChild(a)
          window.URL.revokeObjectURL(url)
        })
      }
    }
  }
}
</script>

<style scoped>
.report {
  overflow-y: auto;
}
</style>
