<template>
  <b-card ref="table" class="p-0 report-table">
    <b-card-header class="d-flex align-items-center py-3">
      <div class="d-inline-flex align-items-center">
        <h4 v-shtml="displayTotalsRecordsHtml" class="text-gray-900 my-0 mr-2" />
        <span>{{ $t('rHostessChat_tbl_found') }}</span>
      </div>
      <div class="ml-auto">
        <h3 v-shtml="displayTotalsHtml" class="text-gray-900 my-0" />
      </div>
      <b-dropdown :text="$t('eLt_export_title')" class="m-md-2" size="sm">
        <b-dropdown-item @click="onSaveCsv">
          {{ $t('eLt_export_csv') }}
        </b-dropdown-item>
        <b-dropdown-item @click="onSaveXls">
          {{ $t('eLt_export_xls') }}
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>
    <b-table
      :id="`dt_${dtKey}`"
      :fields="fields"
      :items="tableItems"
      :sticky-header="`${height}px`"
      class="text-nowrap"
      striped
      hover
      small
      responsive
      foot-clone
      thead-class="bg-primary text-white text-center small"
    >
      <template
        v-for="(date) in dates"
        #[`head(${date}_amount)`]="data"
      >
        <span
          :key="date"
          v-shtml="data.label"
          class="d-block text-center"
        >{{ date }}</span>
      </template>
      <template
        v-for="(date) in dates"
        #[`foot(${date}_amount)`]=""
      >
        <b
          :key="date"
          v-shtml="totals.partial[`${date}_amount`]"
          class="d-block text-right"
        />
      </template>
      <template
        #[`foot(total_amount)`]=""
      >
        <b
          v-shtml="totals.amount"
          class="d-block"
        />
      </template>
      <template
        #[`foot(total_count_talks)`]=""
      >
        <b
          v-shtml="totals.chats"
          class="d-block"
        />
      </template>
      <template
        v-for="(date) in dates"
        #[`cell(${date}_amount)`]="data"
      >
        <span
          :key="date"
          v-shtml="data.value"
          class="d-block"
        />
      </template>
      <template
        #[`cell(user_id)`]="data"
      >
        <a
          v-if="data.item.user_id"
          :href="`/customers/${data.item.user_id}`"
          target="_blank"
        >
          {{ data.item.name }}
        </a>
      </template>
      <template
        #[`cell(total_amount)`]="data"
      >
        <b
          v-shtml="data.value"
          class="d-block"
        />
      </template>
      <template
        #[`cell(total_count_talks)`]="data"
      >
        <b
          v-shtml="data.value"
          class="d-block"
        />
      </template>
      <template #cell(market)="data">
        <market-flag :market="getMarketNameById(data.item.market_id)" />
      </template>
    </b-table>
    <div
      :id="`dt_${dtKey}_pager`"
      ref="pager"
      class="d-flex align-items-start justify-content-between pager"
    >
      <b-pagination
        v-if="currentPage"
        v-model="currentPage"
        class="pb-2"
        :total-rows="pager && pager.total"
        :per-page="pager && pager.per_page"
        :aria-controls="`dt_${dtKey}`"
        :disabled="busy"
      />
      <div
        class="d-inline-flex align-items-center text-nowrap"
      >
        <span class="pr-2 d-none d-md-block d-lg-block">{{ $t('eDt_pageSize') }}</span>
        <b-form-select
          v-model="pageSize"
          :options="pageSizes"
          size="sm"
        />
      </div>
      <strong v-if="pager" class="small d-none d-md-block d-lg-block">
        {{ $t('eDt_iteratorCounts', {
          from: ((pager.current_page - 1) * pager.per_page) + 1,
          to: (pager.current_page) * pager.per_page,
          total: pager.total
        }) }}
      </strong>
    </div>
  </b-card>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { isServer } from '@/mixins/helpers'

export default {
  name: 'HostesChatReportTable',
  components: {},
  props: {
    height: {
      type: Number,
      default: 500
    }
  },
  data () {
    return {
      dtKey: 'hostess_chat_report',
      busy: false,
      thBgColor: '#007bff !important',
      thColor: '#fff !important'
    }
  },
  computed: {
    ...mapFields({
      filterData: 'reports.hostesChatReport.filterData',
      dates: 'reports.hostesChatReport.dates',
      currentPage: 'reports.hostesChatReport.page',
      pageSize: 'reports.hostesChatReport.pageSize',
      reportData: 'reports.hostesChatReport.reportData',
      reportTotals: 'reports.hostesChatReport.reportTotals'
    }),
    items () {
      return this.reportData || []
    },
    totals () {
      return this.reportTotals || {}
    },
    fields () {
      const fields = []

      fields.push({
        key: 'user_id',
        label: '',
        sortable: false,
        class: 'align-middle text-left',
        thClass: 'text-center',
        thStyle: `background: ${this.thBgColor};  color: ${this.thColor};`,
        stickyColumn: true
      })
      fields.push({
        key: 'market',
        label: this.$t('rHostessChat_tbl_f_market'),
        class: 'align-middle text-left',
        thClass: 'text-center',
        thStyle: `background: ${this.thBgColor};  color: ${this.thColor};`
      })
      fields.push({
        key: 'total_amount',
        label: this.$t('rHostessChat_tbl_f_total_amount'),
        sortable: false,
        class: 'align-middle text-right',
        thClass: 'text-center',
        thStyle: `background: ${this.thBgColor};  color: ${this.thColor};`,
        formatter: (value, key, item) => {
          return value ? item.total_amount : ''
        }
      })
      fields.push({
        key: 'total_count_talks',
        label: this.$t('rHostessChat_tbl_f_total_count_talks'),
        sortable: false,
        class: 'align-middle text-right',
        thClass: 'text-center',
        thStyle: `background: ${this.thBgColor};  color: ${this.thColor};`,
        formatter: (value, key, item) => {
          return value ? item.total_count_talks : ''
        }
      })
      if (this.dates) {
        this.dates.forEach((v, i) => {
          if (i + 1 < this.dates.length) {
            const currDate = this.$dateFormat(this.dates[i], 'DD/MM/YYYY')
            const nextDate = this.$dateFormat(this.dates[i + 1], 'DD/MM/YYYY')
            fields.push({
              key: `${v}_amount`,
              label: `<span class="badge badge-pill badge-light" style="font-size:.8rem">${i + 1}</span>
                <small class="text-white d-block mt-1" style="line-height:1.2em">${currDate}<br/>${nextDate}</small>`,
              sortable: false,
              class: 'align-middle text-right',
              thClass: 'text-center',
              thStyle: `background: ${this.thBgColor};  color: ${this.thColor};`,
              formatter: (value, key, item) => {
                let result = value
                if (!result && item) {
                  key = key.replace(/_amount/, '')
                  result = ''
                  if (item[`${key}_amount`]) {
                    result += `<b>${item[`${key}_amount`]}</b>`
                  }
                }
                if (!result) {
                  result = '-'
                }
                return result
              }
            })
          }
        })
      }
      return fields
    },
    tableItems () {
      const items = []
      if (this.items?.data?.length) {
        for (const i of this.items.data) {
          items.push(i)
        }
      }
      return items
    },
    pager () {
      if (this.reportData) {
        return {
          count: this.reportData.count,
          current_page: this.reportData.current_page,
          links: this.reportData.links,
          per_page: this.reportData.per_page,
          total: this.reportData.total,
          total_pages: this.reportData.total_pages
        }
      }
      return {
        count: 0,
        current_page: 0,
        links: [],
        per_page: 0,
        total: 0,
        total_pages: 0
      }
    },
    displayTotalsHtml () {
      return `${this.reportTotals?.amount || 0} <span class='small text-muted'>credits</span> |
          ${this.reportTotals?.chats || 0} <span class='small text-muted'>talks</span>`
    },
    displayTotalsRecordsHtml () {
      return `${this.reportTotals?.records || 0}`
    },
    pageSizes () {
      const values = [
        {
          value: 10,
          text: 10
        },
        {
          value: 25,
          text: 25
        },
        {
          value: 50,
          text: 50
        },
        {
          value: 100,
          text: 100
        },
        {
          value: 200,
          text: 200
        },
        {
          value: 500,
          text: 500
        }
      ]
      const idx = values.findIndex((v) => {
        return v.value === this.pageSize
      })
      if (idx < 0) {
        values.push({
          value: this.pageSize,
          text: this.pageSize
        })
        values.sort((a, b) => {
          if (a.value < b.value) {
            return -1
          }
          if (a.value > b.value) {
            return 1
          }
          return 0
        })
      }
      return values
    }
  },
  watch: {
    currentPage (n, o) {
      if (n !== o && !this.skipReload) {
        this.onPageChanged(n)
      }
    },
    pageSize (n, o) {
      if (n !== o) {
        this.currentPage = 1
        this.onPageSizeChanged(n)
      }
    }
  },
  methods: {
    onPageChanged (page) {
      this.$emit('page-changed', page)
      if (!isServer && window) {
        const havBar = document.querySelector('.wrapper .content .navbar')?.clientHeight || 0
        const table = this.$refs.table.offsetTop || 0
        document.querySelector('.report').scrollTo({ top: table - havBar, behavior: 'smooth' })
        this.$nextTick(() => {
          const $t = document.querySelector('.report-table .table-responsive')
          $t.scrollTo({ top: 0 })
        })
      }
    },
    onPageSizeChanged (size) {
      this.$emit('page-size-changed', size)
      if (!isServer && window) {
        const havBar = document.querySelector('.wrapper .content .navbar')?.clientHeight || 0
        const table = this.$refs.table.offsetTop || 0
        document.querySelector('.report').scrollTo({ top: table - havBar, behavior: 'smooth' })
        this.$nextTick(() => {
          const $t = document.querySelector('.report-table .table-responsive')
          $t.scrollTo({ top: 0 })
        })
      }
    },
    onSaveCsv () {
      this.$emit('save-csv')
    },
    onSaveXls () {
      this.$emit('save-xls')
    },
    getMarketNameById (id) {
      if (this.filterData?.market?.length > 0) {
        const market = this.filterData.market.find(i => i.value === id)
        if (market) {
          return market.text
        }
      }
      return null
    }
  }
}
</script>

<style>
.report-table .table.b-table > thead > tr > .table-b-table-default {
  background:#007bff !important;
  color:#fff !important;
}
.report-table .table.b-table > tfoot > tr > th {
  background: #737373 !important;
  color:#fff !important;
  text-align: right !important;
}
</style>
